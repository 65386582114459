import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ProductDisplay from "./ProductDisplay";
import { Box, CardActionArea } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ProductCardPopup from "./ProductCardPopup";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ForSale from "./ForSale";
import FreeShipping from "./FreeShipping";

export const ProductCard = ({
  product,
  quantities,
  handleQuantityChange,
  handleAddToCartClick,
}) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setExpanded(!expanded);
  };
  const open = () => {
    // setExpanded(!expanded);
  };
  const capitalizeFirstLetter = str => {
    if (typeof str === 'string' && str.length > 0) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={6}
      lg={3}
      xl={2}
      key={product.Id}
      sx={{
        minWidth: 300,
        maxWidth: { xs: 300, sm: "none", md: "none", lg: "none", xl: "none" },
      }}
    >
      <Card
        sx={{
          border: "1px solid #f0f8ff",
          minWidth: 290,
        }}
      >
        <CardActionArea>
          <Typography
            color="primary"
            variant="h6"
            style={{
              fontSize: 16,
              paddingLeft: 10,
              paddingTop: 5,
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              position: "relative",
              // minHeight: 55,
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Tooltip title={product.ProductName}>
                <div className="ellipsis">
                  {capitalizeFirstLetter(product.ProductName)}
                </div>
              </Tooltip>
              <IconButton
                // color="primary"
                style={{
                  color: "#dd5353",
                  paddingTop: 0
                }}
                // style={{ color: "#be4700" }}
                onClick={() => navigate(`/product/${product.Id}`)}
              >
                <OpenWithIcon />
              </IconButton>
            </Box>
            <Typography>
              {product.IsForSale !== 1 && product.FreeShipping !== 1 ? (
                <div> </div>
              ) : (
                <>
                  {product.IsForSale === 1 && <ForSale />}
                  {"  "}
                  {product.FreeShipping === 1 && <FreeShipping />}
                </>
              )}
            </Typography>
          </Typography>
          <ProductDisplay
            product={{
              media: [
                { type: "img", url: product.MainImageUrl },
                ...product.OtherUrls.map(url => ({
                  type: "img",
                  url,
                })),
                // {
                //   type: "video",
                //   url: "https://www.youtube.com/watch?v=16_9ba6pSRk&t=78s",
                // },
              ],
              MainImageUrl: product.MainImageUrl,
              OtherUrls: product.OtherUrls,
            }}
          ></ProductDisplay>

          <CardContent sx={{ borderTop: "none", borderBottom: "none" }}>
            <Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" component="div">
                  {product?.Price?.toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })}
                </Typography>
                <Button
                  style={{
                    backgroundColor: "#59c86b",
                    borderColor: "#59c86b",
                    color: "white",
                  }}
                  variant="contained"
                  onClick={() => handleAddToCartClick(product)}
                >
                  Add To Cart
                </Button>
              </Box>
            </Typography>
            <Typography>
              <>
                {false && (
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      style={{
                        paddingTop: 5,
                      }}
                    >
                      Qty:&nbsp;
                      <TextField
                        type="number"
                        inputProps={{ min: 1 }}
                        value={quantities[product.Id] || 1}
                        onChange={event =>
                          handleQuantityChange(product.Id, event)
                        }
                        sx={{
                          height: "20px",
                          width: "90px",
                          paddingBottom: "15px",
                          "& .MuiOutlinedInput-input": {
                            padding: "3px 14px",
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "0.8rem",
                          },
                          "& .MuiInputLabel-outlined": {
                            transform: "translate(14px, 9px) scale(1)",
                          },
                          "& .MuiInputLabel-shrink": {
                            transform: "translate(14px, -6px) scale(0.75)",
                          },
                        }}
                      />
                    </Typography>
                    <Button
                      style={{
                        backgroundColor: "#59c86b",
                        borderColor: "#59c86b",
                        color: "white",
                      }}
                      variant="contained"
                      onClick={() => handleAddToCartClick(product)}
                    >
                      Add To Cart
                    </Button>
                  </Box>
                )}{" "}
              </>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      {expanded && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="dialog-title"
          open={open}
          // maxWidth="lg" // Make it wider
          // PaperProps={{ style: { maxHeight: "90vh" } }} // Make it taller
        >
          <DialogTitle id="dialog-title">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color="primary">Product Details</Typography>
              <IconButton color="primary" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>

          <ProductCardPopup
            key={product.Id}
            product={product}
            quantities={quantities}
            handleQuantityChange={handleQuantityChange}
            handleAddToCartClick={handleAddToCartClick}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default ProductCard;
