import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { getProducts } from "../api/products";
import { addToCart } from "../store/reducers/cartSlice";
import Grid from "@mui/material/Grid";
import { loginSuccess } from "store/reducers/authSlice";
import Search from "./Search";
import ProductCard from "./ProductCard";
import Spinner from "./Spinner";

export const Shopping = () => {
  const [response, setResponse] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const [quantities, setQuantities] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(0);
  const activeFilters = useSelector(state => state.search?.activeFilters); // Get activeFilters from the Redux store
  const sortType = useSelector(state => state.search?.sortType); // Get sort type from the Redux store
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search.toLocaleLowerCase());
  const filterParam = urlParams.get('filter');
  const productIdParam = urlParams.get('productid');

  useEffect(() => {
    async function getSession() {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      const accessToken = session.getAccessToken().getJwtToken();
      const refreshToken = session.getRefreshToken().getToken();
      dispatch(loginSuccess({ idToken, accessToken, refreshToken }));
    }
    getSession();
    fetchData();
  }, []);

  useEffect(() => {
    if (filterParam || productIdParam) {
      // Apply the filter from the URL parameter
      const filterData = () => {
        if (
          !response ||
          response.length === 0
        )
          return response;
  
        const lowerCaseFilterParam = filterParam?.toLowerCase() ?? '';
        const lowerCaseProductIdParam = productIdParam?.toLowerCase() ?? '';

        if (lowerCaseProductIdParam.length > 0) {
          return response.filter(item => item.ProductID?.toString() === lowerCaseProductIdParam);

        }
  
        return response.filter(item => {
          const lowerCaseValues = Object.values(item).map(value =>
            value?.toString().toLowerCase()
          );
          return lowerCaseValues.some(value => value?.includes(lowerCaseFilterParam));
        });
      };
  
      const filterDataSet = filterData();
      setFilteredData(filterDataSet);
    }
  }, [response, filterParam, productIdParam]);

  useEffect(() => {
    const filterData = () => {
      if (
        !response ||
        response.length === 0 ||
        !activeFilters ||
        activeFilters?.length === 0
      )
        return response;

      const lowerCaseActiveFilters = activeFilters.map(filter =>
        filter.toLowerCase()
      );

      return response.filter(item => {
        const lowerCaseValues = Object.values(item).map(value =>
          value?.toString().toLowerCase()
        );
        return lowerCaseActiveFilters.some(filter =>
          lowerCaseValues.some(value => value?.includes(filter))
        );
      });
    };

    const filterDataSet = filterData();
    setFilteredData(filterDataSet);
  }, [activeFilters]);

    useEffect(() => {
    const filterData = () => {
      if (
        !response ||
        response.length === 0 ||
        !sortType ||
        sortType?.length === 0
      )
        return response;

        switch (sortType) {
          case 'Price: Low -> High':
            return [...response].sort((a, b) => a.Price - b.Price);
          case 'Price: High -> Low':
            return [...response].sort((a, b) => b.Price - a.Price);
          case 'New -> Old':
            return [...response].sort((a, b) => a.ProductId - b.ProductId); // Replace 'timestamp' with your actual timestamp field
          case 'Old -> New':
            return [...response].sort((a, b) => b.ProductId - a.ProductId); // Replace 'timestamp' with your actual timestamp field
          case 'Title: A-Z':
            return [...response].sort((a, b) => a.ProductName.localeCompare(b.ProductName));
          case 'Title: Z-A':
            return [...response].sort((a, b) => b.ProductName.localeCompare(a.ProductName));
          default:
            return [...response].sort((a, b) => b.IsForSale - a.IsForSale);
        }
    };

    const filterDataSet = filterData();
    setFilteredData(filterDataSet);
  }, [sortType]);

  const fetchData = async () => {
    try {
      setLoading(1);
      let response = await getProducts();
      if (!response) { return }
      // response = response
      // .filter(item => item.ProductType && item.ProductType.includes("-"))
      // .map(item => {
      //   const [category, subCategory, subSubCategory] = item.ProductType.split("-");
      //   return {
      //     ...item,
      //     Category: category?.trim(),
      //     SubCategory: subCategory?.trim() ?? category?.trim(),
      //     SubSubCategory: subSubCategory?.trim() ?? category?.trim(),
      //   };
      // });
    

      // Sort by IsForSale
      response.sort((a, b) => {
        if ((b.IsForSale ?? 0) < (a.IsForSale ?? 0)) {
          return -1;
        }
        if ((b.IsForSale ?? 0) > (a.IsForSale ?? 0)) {
          return 1;
        }
        return 0;
      });
      setResponse(response);
      setFilteredData(response);
      setLoading(0);
    } catch (error) {
      setLoading(0);
      console.error(error.message);  // Log the error message to the console
    }
  };

  const handleQuantityChange = (productId, event) => {
    setQuantities({
      ...quantities,
      [productId]: event.target.value,
    });
  };

  const handleAddToCartClick = product => {
    dispatch(
      addToCart({
        item: product,
        quantity: parseInt(quantities[product.Id] || 1),
      })
    );
  };

  return (
    <>
      {loading === 1 && <Spinner />}
      {response && filteredData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            // width: "99%",
            ml: 2,
            mr: 2,
          }}
        >
          <div>
            <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                {filteredData?.map(product => (
                  <ProductCard
                    key={product.Id}
                    product={product}
                    quantities={quantities}
                    handleQuantityChange={handleQuantityChange}
                    handleAddToCartClick={handleAddToCartClick}
                  />
                ))}
              </Grid>
            </Grid>
          </div>
        </Box>
      )}
    </>
  );
};

export default Shopping;
