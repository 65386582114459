import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { loginSuccess } from "store/reducers/authSlice";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ProductDisplay from "./ProductDisplay";
import { CardActionArea } from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import { getProduct } from "../api/products";
import Spinner from "./Spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import { addToCart } from "../store/reducers/cartSlice";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { IconButton } from "@mui/material";
import ForSale from "./ForSale";
import FreeShipping from "./FreeShipping";
import logo from "../logo/noImage.jpg";

export const ProductDetailCard = () => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const capitalizeFirstLetter = str => {
    if (typeof str === 'string' && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  useEffect(() => {
    async function getSession() {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      const accessToken = session.getAccessToken().getJwtToken();
      const refreshToken = session.getRefreshToken().getToken();
      dispatch(loginSuccess({ idToken, accessToken, refreshToken }));
    }
    getSession();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(1);
      let response = await getProduct(id);
      setProduct(response);
      setLoading(0);
    } catch {
      setLoading(0);
    }
  };

  const handleAddToCartClick = product => {
    dispatch(
      addToCart({
        item: product,
        quantity: 1,
      })
    );
  };

  return (
    <>
      {loading === 1 && <Spinner />}
      {product && (
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            key={product?.Id}
            sx={{
              marginLeft: 1,
              marginRight: 1,
              paddingTop: 2,
              minWidth: { sm: 300, md: 380, lg: 800, xl: 1000 },
            }}
          >
            <Card
              sx={{
                border: "1px solid #f0f8ff",
                minWidth: 260,
                maxWidth: 1000,
              }}
            >

              <Typography
                color="primary"
                gutterBottom
                variant="h5"
                component="div"
                style={{}}
              >
                <Tooltip title={product?.ProductName}>
                  {capitalizeFirstLetter(product?.ProductName)}
                </Tooltip>
              </Typography>
              <ProductDisplay
                product={{
                  media: [
                    { type: "img", url: product.MainImageUrl },
                    ...product?.OtherUrls.map(url => ({
                      type: "img",
                      url,
                    })),
                  ],
                  MainImageUrl: product?.MainImageUrl,
                  OtherUrls: product?.OtherUrls,
                }}
                height="250"
                width="300"
              />
              <Typography>{product?.Price.toLocaleString("en-AU", {
                style: "currency",
                currency: "AUD",
              })}</Typography>

              <CardContent>
                <Table>
                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}></TableCell>
                      <TableCell>
                        <Typography style={{ paddingTop: 10 }}>
                          <Button
                            style={{
                              backgroundColor: "#59c86b",
                              borderColor: "#59c86b",
                              color: "white",
                            }}
                            variant="contained"
                            onClick={() => handleAddToCartClick(product)}
                          >
                            Add To Cart
                          </Button>    {"   "}
                          {product.IsForSale === 1 && <ForSale />}
                          <span>
                            {product.FreeShipping === 1 && <FreeShipping />}
                          </span>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: "20%" }}>ID</TableCell>
                      <TableCell align="left">
                        <Tooltip title={product?.Id}>{product?.Id}</Tooltip>
                        {"   "}
                        <Tooltip title="Use this link to share the product on social media sites to other people">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                `${window.location.origin}/product/${product?.Id}`
                              )
                            }
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Product ID</TableCell>
                      <TableCell align="left">
                        <Tooltip title={product?.ProductID}>
                          {product?.ProductID}
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell align="left">
                        <Tooltip title={product?.ProductName}>
                          {product?.ProductName}
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Product Description</TableCell>
                      <TableCell align="left">
                        <Tooltip title={product?.ProductDescription}>
                          {capitalizeFirstLetter(product?.ProductDescription)}
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Product Details</TableCell>
                      <TableCell align="left">
                        <Tooltip title={product?.ProductDetails}>
                          {capitalizeFirstLetter(product?.ProductDetails)}
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Weight</TableCell>
                      <TableCell align="left">
                        <Tooltip title={product?.Weightkg}>
                          Weight: {product?.Weightkg} kg
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Dimensions</TableCell>
                      <TableCell align="left">
                        <Tooltip title="Dimensions">
                          H:{product?.Heightcm}cm X W:{product.Widthcm} cm X
                          L:
                          {product.Lengthcm} cm
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Price</TableCell>
                      <TableCell align="left">
                        {product?.Price.toLocaleString("en-AU", {
                          style: "currency",
                          currency: "AUD",
                        })}{" "}
                        {product.IsForSale === 1 && <ForSale />}
                        <span>
                          {product.FreeShipping === 1 && <FreeShipping />}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">{/* {" "} */}</TableCell>
                      <TableCell>
                        <Button color="primary" onClick={() => navigate(`/`)}>
                          Continue Shopping
                        </Button>
                        {"   "}
                        <Button
                          style={{
                            backgroundColor: "#59c86b",
                            borderColor: "#59c86b",
                            color: "white",
                          }}
                          variant="contained"
                          onClick={() => handleAddToCartClick(product)}
                        >
                          Add To Cart
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
              <br></br>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProductDetailCard;
