import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Hidden } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilter,
  removeFilter,
  removeAllFilters,
  addSorting,
} from "../store/reducers/searchSlice";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const Search = ({ searchTerm, setSearchTerm }) => {
  const dispatch = useDispatch();
  const activeFilters = useSelector(state => state.search?.activeFilters);
  const [sortType, setSortType] = useState("Relevancy");
  const [filterOption, setFilterOption] = useState("All");

  useEffect(() => { 
    if (searchTerm.length > 1) { 
      dispatch(removeAllFilters()); 
      handleFilterChange(searchTerm); 
    } else { 
      dispatch(removeAllFilters()); 
    } }, [searchTerm]);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleFilterChange(event.target.value);
    }
  };

  const handleDelete = filterToRemove => () => {
    dispatch(removeFilter(filterToRemove));
    setSearchTerm("");
  };

  const handleFilterChange = filter => {
    if (filter?.toUpperCase() === "ALL") {
      dispatch(removeFilter("Computers - Laptop Computers"));
      dispatch(removeFilter("Electronics - Cell Phones and Accessories"));
      dispatch(removeFilter("Computers - Computer Components"));
      return;
    }
    if (activeFilters.includes(filter)) {
      // dispatch(removeFilter(filter)); // Dispatch removeFilter action
    } else {
      dispatch(addFilter(filter)); // Dispatch addFilter action
    }
  };

  const handleFilterOptionChange = event => {
    setFilterOption(event.target.value);
    handleFilterChange(event.target.value);
  };

  const handleSortChange = event => {
    dispatch(addSorting(event)); // Dispatch addFilter action
    setSortType(event);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        minWidth: 300,
        mt: 2,
        mb: 2,
        ml: 0,
        pl: 0,
      }}
    >
      <Hidden mdDown>
        <Select
          value={filterOption}
          onChange={handleFilterOptionChange}
          sx={{ width: "15%", mr: 1, ml: 0 }}
        >
          <MenuItem value="All">Show All</MenuItem>
          <MenuItem value="Computers - Laptop Computers">Laptops</MenuItem>
          <MenuItem value="Electronics - Cell Phones and Accessories">Phones</MenuItem>
          <MenuItem value="Computers - Computer Components">Accessories</MenuItem>
          {/* <MenuItem value="Books - Computers & Technology">Option 1</MenuItem>
        <MenuItem value="Books - Computers & Technology">Option 1</MenuItem>
         */}

          {/* Add more options as needed */}
        </Select>
      </Hidden>
      <TextField
        label="Search for a product"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        type="search"
        sx={{ width: "60%" }}
        onKeyDown={handleKeyDown}
      />
      <Select
        value={sortType}
        onChange={e => handleSortChange(e.target.value)}
        sx={{ width: "20%", mr: 1, ml: 1 }}
      >
        <MenuItem value="Relevancy">Relevancy</MenuItem>
        <MenuItem value="Price: Low -> High">Price: Low -&gt; High</MenuItem>
        <MenuItem value="Price: High -> Low">Price: High -&gt; Low</MenuItem>
        <MenuItem value="New -> Old">New -&gt; Old</MenuItem>
        <MenuItem value="Old -> New">Old -&gt; New</MenuItem>
        <MenuItem value="Title: A-Z">Title: A-Z</MenuItem>
        <MenuItem value="Title: Z-A">Title: Z-A</MenuItem>
        <MenuItem value="Popularity">Popularity</MenuItem>
      </Select>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          flexWrap: "wrap",
          pt: 2,
        }}
      >
        {activeFilters?.map(filter => (
          <Chip
            color="secondary"
            key={filter}
            label={filter}
            onDelete={handleDelete(filter)}
          />
        ))}

        {activeFilters && activeFilters.length > 0 && (
          <Chip
            color="secondary"
            key="Clear all filters"
            label="Clear all filters"
            onClick={() => {
              dispatch(removeAllFilters());
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Search;
