import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Stack from "@mui/material/Stack";
import { Auth } from "aws-amplify";
import { addProducts } from "../api/products";
import jwt_decode from "jwt-decode";

import * as XLSX from "xlsx";
import { decodeReadableStream } from "api/util";
import { Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../store/reducers/authSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getToken, batchProductUpdate } from "../api/ingram";

const useStyles = makeStyles({
  button: {
    margin: "0 10px",
  },
  typography: {
    margin: "10px",
  },
});

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // height: '100vh'
};

const divStyle = {
  margin: "30px",
  padding: "20px",
  height: "200px",
  width: "600px",
  border: "2px dashed #ccc",
};

const ExcelReader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [response, setResponse] = useState(null);
  const [items, setItems] = useState([]);
  const [idToken, setIdToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [vendors, setVendors] = useState("Hp");

  useEffect(() => {
    async function getSession() {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      const accessToken = session.getAccessToken().getJwtToken();
      const refreshToken = session.getRefreshToken().getToken();
      dispatch(loginSuccess({ idToken, accessToken, refreshToken }));
      setIdToken(idToken);
      setAccessToken(accessToken);
    }
    getSession();
  }, []);

  const handleSaveProductsClick = async () => {
    setLoading(true);
    try {
        if (idToken) {
            const chunkSize = 100;
            const promises = [];

            for (let i = 0; i < items.length; i += chunkSize) {
                const chunk = items.slice(i, i + chunkSize);
                promises.push(addProducts(chunk));
            }

            const results = await Promise.all(promises);
            results.forEach(result => {
                setResponse(prev => `${prev || ''}\n${result?.message}`);
            });
        }
    } catch (error) {
        console.error(error);
    }
    setLoading(false);
};

  const handleFileUpload = e => {
    setLoadingFile(true);
    const file = e.target.files[0];
    processFile(file);
    setLoadingFile(false);
  };

  const handleDrop = files => {
    const file = files[0];
    processFile(file);
  };

  const processFile = file => {
    const reader = new FileReader();
    reader.onload = event => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Read the 'Product' worksheet
      const productSheet = workbook.Sheets["Products"];
      const productData = XLSX.utils.sheet_to_json(productSheet, { header: 1 });
      const productHeaders = productData[0];
      const products = productData.slice(1).map(row => {
        if (!isNaN(row[0]) && +row[0] !== 0) {
          const product = {};
          row.forEach((cell, index) => {
            let key;
            switch (productHeaders[index]) {
              case "0":
                key = "ProductID";
                break;
              case "1":
                key = "ProductName";
                break;
              case "2":
                key = "ExternalProductId";
                break;
              case "ProductType":
                key = "ProductType";
                break;
              case "ProductDescription":
                key = "ProductDescription";
                break;
              case "ProductDetails":
                key = "ProductDetails";
                break;
              case "SKU":
                key = "SKU";
                break;
              case "Brand Name":
                key = "BrandName";
                break;
              case "Quantity":
                key = "Quantity";
                break;
              case "Price":
                key = "Price";
                break;
              case "Weight(kg)":
                key = "Weightkg";
                break;
              case "Length(cm)":
                key = "Lengthcm";
                break;
              case "Width(cm)":
                key = "Widthcm";
                break;
              case "Height(cm)":
                key = "Heightcm";
                break;
              case "ActualCost":
                key = "ActualCost";
                break;
              case "IsForSale":
                key = "IsForSale";
                break;
              case "FreeShipping":
                key = "FreeShipping";
                break;
              case "FreeGiveAway":
                key = "FreeGiveAway";
                break;
              case "FreeGiveAwayGUID":
                key = "FreeGiveAwayGUID";
                break;
              case "ShowOnSideBar":
                key = "ShowOnSideBar";
                break;
              case "MainImageUrl":
                key = "MainImageUrl";
                break;
              default:
                key = productHeaders[index];
            }
            product[key] = cell;
          });
          return product;
        }
      });

      // Read the 'ImageUrls' worksheet
      const imageSheet = workbook.Sheets["ImageUrls"];
      const imageData = XLSX.utils.sheet_to_json(imageSheet, { header: 1 });
      const imageHeaders = imageData[0];
      let images = imageData.slice(1).map(row => {
        let image = {};
        if (!isNaN(row[0]) && +row[0] !== 0) {
          for (let index = 0; index < row.length; index++) {
            const cell = row[index];
            if (cell != null && cell != "") {
              let key;
              switch (productHeaders[index]) {
                case "0":
                  key = "ProductID";
                  break;
                case "1":
                  key = "ImgUrl1";
                  break;
                case "2":
                  key = "ImgUrl2";
                  break;
                case "3":
                  key = "ImgUrl3";
                  break;
                case "4":
                  key = "ImgUrl4";
                  break;
                case "5":
                  key = "ImgUrl5";
                  break;
                case "6":
                  key = "ImgUrl6";
                  break;
                case "7":
                  key = "ImgUrl7";
                  break;
                case "8":
                  key = "ImgUrl8";
                  break;
                case "9":
                  key = "ImgUrl9";
                  break;
                case "10":
                  key = "ImgUrl10";
                  break;
                case "11":
                  key = "ImgUrl11";
                  break;
                case "12":
                  key = "ImgUrl12";
                  break;
                case "13":
                  key = "ImgUrl13";
                  break;
                case "14":
                  key = "ImgUrl14";
                  break;
                case "15":
                  key = "ImgUrl15";
                  break;
                case "16":
                  key = "ImgUrl16";
                  break;
                case "17":
                  key = "ImgUrl17";
                  break;
                case "18":
                  key = "ImgUrl18";
                  break;
                case "19":
                  key = "ImgUrl19";
                  break;
                case "20":
                  key = "ImgUrl20";
                  break;
                case "21":
                  key = "ImgUrl21";
                  break;
                case "22":
                  key = "ImgUrl22";
                  break;
                case "23":
                  key = "ImgUrl23";
                  break;
                default:
                  key = productHeaders[index];
              }
              image[imageHeaders[index]] = cell;
            } else {
              return image;
            }
          }
        }
        return image;
      });


      // Combine the data
      const decodedToken = jwt_decode(accessToken);
      const customerId = decodedToken["sub"];
      const validProducts = products.filter(product => product !== undefined);
      const validImages = images.filter(image => image !== undefined);
      validProducts.forEach((product, index) => {
        const filteredImages = validImages.filter(
          image => image.ProductID === product.ProductID
        );
        const imageUrls = [];
        filteredImages.forEach((images, index) => {
          const allUrls = [];
          for (const key in images) {
            if (key.startsWith("ImgUrl")) {
              const value = images[key];
              allUrls.push(value);
            }
          }
          imageUrls.push(allUrls);
        });

        product["OtherUrls"] = imageUrls[0];
        product["Id"] = `${customerId}-${product["ProductID"]}`;
        console.log(product["Id"]);
      });

      setItems(validProducts);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleInputChange = event => {
    setVendors(event.target.value);
  };

  const handleProductsExport = data => {

    fetch('/ProductUploadTemplate.xlsx')
      .then(response => response.arrayBuffer())
      .then(excelData => {
        const workbook = XLSX.read(new Uint8Array(excelData), { type: 'array' });

        // Get the first worksheet
        const worksheetName = vendors; // workbook.SheetNames[0];
        //const worksheet = workbook.Sheets[worksheetName];

        // Convert the worksheet to JSON
        let jsonData = data; //XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Add data to jsonData here...

        // Convert the JSON data back to a worksheet
        const newWorksheet = XLSX.utils.json_to_sheet(jsonData);

        // Replace the old worksheet with the new one
        // workbook.Sheets[worksheetName] = newWorksheet;

        // Add the new worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, newWorksheet, worksheetName);

        // Write the workbook back to a file
        XLSX.writeFile(workbook, 'ProductUploadTemplate.xlsx');
      });

    /*
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, vendors);
    XLSX.writeFile(wb, "productsData.xlsx");
    */
  };

  const getIngramMicroDataHandler = async () => {
    let productsWithPrices = [];
    // const vendors = "Samsung"; //,Hp,Lenovo
    let { token, custNo } = await getToken();
    const baseUrl = "https://api.ingrammicro.com:443";
    const catalogFilters = `pageSize=50&vendor=${vendors}&category=Computers&type=IM::physical`;
    let searchUrl = `${baseUrl}/resellers/v6/catalog?pageNumber=1&${catalogFilters}`;

    while (searchUrl) {
      try {
        const response = await axios.get(searchUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "IM-CustomerNumber": custNo.toString(),
            "IM-CountryCode": "AU",
            "IM-CorrelationID": uuidv4(),
            "IM-SenderID": "ApricotSoftwarePtyLtd",
            "Content-Type": "application/json",
          },
        });

        const data = response.data;

        //update search url
        if (data.nextPage) {
          searchUrl = `${baseUrl}/${data.nextPage}`;
          console.log(searchUrl);
        } else {
          searchUrl = undefined;
        }

        // Prepare the products array for the Price and Availability call
        const products = data.catalog.map(item => ({
          ingramPartNumber: item.ingramPartNumber,
        }));

        // Get Price and Availability
        const priceAndAvailabilityUrl = `${baseUrl}/resellers/v6/catalog/priceandavailability?includeAvailability=true&includePricing=true&includeProductAttributes=true`;
        const priceAndAvailabilityResponse = await axios.post(
          priceAndAvailabilityUrl,
          {
            products,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "IM-CustomerNumber": custNo.toString(),
              "IM-CountryCode": "AU",
              "IM-CorrelationID": uuidv4(),
              "IM-SenderID": "ApricotSoftwarePtyLtd",
              "Content-Type": "application/json",
            },
          }
        );

        let priceAndAvailabilityData = priceAndAvailabilityResponse.data;

        // Combine the responses and write data to DynamoDB
        // Iterate over each item in the catalog
        for (let item of data.catalog) {
          // Find the corresponding Price and Availability data
          const priceAndAvailabilityItem = priceAndAvailabilityData.find(
            p => p.ingramPartNumber === item.ingramPartNumber
          );

          if (priceAndAvailabilityItem) {
            // Combine the item data with the corresponding Price and Availability data
            const combinedItem = {
              ...item,
              ...priceAndAvailabilityItem,
            };

            const flattenedObject = flattenObject(combinedItem);

            // Write the API
            console.log(flattenedObject);
            productsWithPrices.push(flattenedObject);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    handleProductsExport(productsWithPrices);

    // make batch api call to save to dynamo DB -- DO NOT DELETE can be used to save data
    // const batchUpdate = await batchProductUpdate(JSON.stringify(productsWithPrices))
    //   .catch(ex => console.log(ex))
    //   .finally(e => {
    //     console.log(e);
    //   });

    productsWithPrices = [];
    //console.log(batchUpdate);
    console.log("done batch product update");
  };

  function flattenObject(obj, parentKey = '', result = {}) {
    for (let key in obj) {
      let newKey = `${parentKey}${parentKey ? '.' : ''}${key}`;
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        flattenObject(obj[key], newKey, result);
      } else {
        result[newKey] = obj[key];
      }
    }
    return result;
  }

  return (
    <div style={{ paddingTop: "16px" }}>
      <Typography>
        If you are a new seller, you can download the spreadsheet below, fill it
        in and then upload it here.
      </Typography>
      <Typography>
        <a
          href={`${process.env.PUBLIC_URL}/ProductUploadTemplate.xlsx`}
          download
        >
          Download Spreadsheet
        </a>
      </Typography>
      <Typography
        className={classes.typography}
        variant="span"
        component="span"
      >
        Either drag and drop a file in the space provided or click upload. Click
        Add products once uploaded
      </Typography>
      <Stack spacing={1}>
        <Dropzone
          onDrop={handleDrop}
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          {({ getRootProps, getInputProps }) => (
            <div style={containerStyle} {...getRootProps()}>
              <input {...getInputProps()} />
              <div style={divStyle}>
                Drag and drop an excel file here or click
              </div>
            </div>
          )}
        </Dropzone>
      </Stack>
      <Button className={classes.button} variant="contained" component="label">
        {loadingFile && <CircularProgress />}
        Upload File
        <input type="file" hidden onChange={handleFileUpload} />
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        onClick={handleSaveProductsClick}
      >
        {loading && <CircularProgress />}Add Products
      </Button>
      <Typography
        className={classes.typography}
        variant="span"
        component="span"
      >
        {loadingFile && <div>Loading the file and processing it</div>}
        {loading && <div>Uploading the data to the online system</div>}
      </Typography>
      <hr />
      <p>
        <input type="text" value={vendors} onChange={handleInputChange} />
        <Button
          className={classes.button}
          variant="contained"
          onClick={getIngramMicroDataHandler}
        >
          Get Products from Ingram
        </Button>
      </p>
    </div>
  );
};

export default ExcelReader;
