import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ProductDisplay from "./ProductDisplay";
import { CardActionArea } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

export const ProductCardPopup = ({
  product,
  quantities,
  handleQuantityChange,
  handleAddToCartClick,
}) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const capitalizeFirstLetter = str => {
    if (typeof str === 'string' && str.length > 0) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };
  return (
    <Grid item key={product.Id} sx={{ minWidth: { sm: 300, md:380} }}>
      <Card
        sx={{
          border: "1px solid #f0f8ff",
          minWidth: 260,
        }}
      >
        <CardActionArea>
          <ProductDisplay
            product={{
              media: [
                { type: "img", url: product.MainImageUrl },
                ...product.OtherUrls.map(url => ({
                  type: "img",
                  url,
                })),
                {
                  type: "video",
                  url: "https://www.youtube.com/watch?v=16_9ba6pSRk&t=78s",
                },
              ],
              MainImageUrl: product.MainImageUrl,
              OtherUrls: product.OtherUrls,
            }}
          />

          <CardContent>
            {/* <Box display="flex" alignItems="center" justifyContent="left"> */}
              <Typography
                color="primary"
                gutterBottom
                variant="h5"
                component="div"
                style={{

                }}
              >
                <Tooltip title={product.ProductName}>
                  {capitalizeFirstLetter(product.ProductName)}
                </Tooltip>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{

                }}
              >
                <Tooltip title={product.ProductDescription}>
                  {capitalizeFirstLetter(product.ProductDescription)}
                </Tooltip>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{
   
                }}
              >
                <Tooltip title={product.ProductSKU}>
                  {product.ProductSKU}
                </Tooltip>
              </Typography>
              <Typography variant="h6" component="div">
                {product.Price.toLocaleString("en-AU", {
                  style: "currency",
                  currency: "AUD",
                })}
              </Typography>
            {/* </Box> */}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ProductCardPopup;
